import React, {useEffect} from "react";
import { MoveRight } from "lucide-react";
import SisterCompanies from "../components/sister-companies";

export default function Home() {

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const offset = 80; // Offset value in pixels
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset;
        window.scrollBy({ top: offsetPosition, behavior: 'smooth' });
      }
    }
  }, []);

  return (
      <div>
        <div className="bg-white">
          <div className="flex flex-col lg:flex-row lg:items-center order-2 lg:order-1 flex-wrap gap-4 2xl:gap-16">
            <div className="order-2 lg:order-1 sm:pl-16 py-6">
              <h1 className="mb-3 px-4 text-4xl leading-tight tracking-wide font-din">
                A legacy of quality,
                <br/>
                125 years & counting.
              </h1>
              <p className="px-4 font-ibm max-w-sm text-lg leading-tight text-black xl:text-gray-500">
                Lawton Printers was founded in 1900 by J.B. Lawton and is one of the oldest printing
                companies in the United States. Today, the nation&apos;s longest consecutively
                family-owned printing company&ndash;Lawton Connect&ndash;continues to evolve and
                thrive.
              </p>
            </div>
            <div className="order-1 lg:order-2 flex-1">
              <img
                  src={Math.floor(Math.random() * 2) ? "./hero.png" : "./hero2_new.png"}
                  alt="hero"
                  className="max-h-[395px] object-cover w-full"
              />
            </div>
          </div>
        </div>
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-12 gap-y-4 gap-x-6">
          <div className="bg-white shadow">
            <img src="./Rectangle 54.jpg" alt="" className="h-[250px] w-full object-cover"/>
            <div className="p-4">
              <h2 className="font-din text-[20px]">Sheetfed Printing</h2>
              <p className="font-ibm leading-tight mb-2 text-sm">
                Solutions utilizing the latest equipment to deliver you the finest finished products
              </p>
              <a href="/solutions#printing" className="group flex items-center space-x-3 uppercase font-din">
              <span className="transition group-hover:opacity-80 font-din text-[#4D4D4D]">
                Learn More
              </span>
                <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3"/>
              </a>
            </div>
          </div>
          <div className="bg-white shadow">
            <img src="./card.jpg" alt="" className="h-[250px] w-full object-cover"/>
            <div className="p-4">
              <h2 className="font-din text-[20px]">Wide Format</h2>
              <p className="font-ibm leading-tight mb-2 text-sm">
                Wide variety for your environmental marketing and display needs
              </p>
              <a href="/solutions#wide_format" className="group flex items-center space-x-3 uppercase">
              <span className="transition group-hover:opacity-80 font-din text-[#4D4D4D]">
                Learn More
              </span>
                <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3"/>
              </a>
            </div>
          </div>
          <div className="bg-white shadow">
            <img src="./menu_printing.jpg" alt="" className="h-[250px] w-full object-cover"/>
            <div className="p-4">
              <h2 className="font-din text-[20px]">Menu Printing</h2>
              <p className="font-ibm leading-tight mb-2 text-sm">
                Restaurant solutions and systems customized for you
              </p>
              <a href="/solutions#menu_printing" className="group flex items-center space-x-3 uppercase">
              <span className="transition group-hover:opacity-80 font-din text-[#4D4D4D]">
                Learn More
              </span>
                <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3"/>
              </a>
            </div>
          </div>
          <div className="bg-white shadow">
            <img src="./rec2.jpg" alt="" className="h-[250px] w-full object-cover"/>
            <div className="p-4">
              <h2 className="font-din text-[20px]">Specialty</h2>
              <p className="font-ibm leading-tight mb-2 text-sm">
                Novelty products including scratch-off, playing cards, promotional items & more
              </p>
              <a href="/solutions#speciality" className="group flex items-center space-x-3 uppercase">
              <span className="transition group-hover:opacity-80 font-din text-[#4D4D4D]">
                Learn More
              </span>
                <MoveRight className="text-[#4D4D4D] transition group-hover:translate-x-3"/>
              </a>
            </div>
          </div>
        </div>

        <div className="">

        <div className="flex items-center justify-center gap-16 pb-10 px-4">
          <div>
            <h2 className="text-[40px] mb-2 mt-4 font-din text-center">Create + Share</h2>
            <p className="max-w-2xl text-md font-din text-center">
              We love helping our customers creative vision come to life. And we love it more when they share it
              with the world. Tag your project with #lawtonconnect and we’ll showcase it here on our website.
            </p>
          </div>
        </div>

        <div className="container mb-20">
          <div className="grid grid-cols-5 gap-4">
            <div className="row-span-2 col-span-2">
              <img src="./Rectangle 36.png" alt="" className=""/>
            </div>
            <div className="col-span-3">
              <img src="./Rectangle 37.png" alt="" className=""/>
            </div>
            <div>
              <img src="./Rectangle 38.png" alt="" className="h-[319px]"/>
            </div>
            <div className="">
              <img src="./Rectangle 39.png" alt="" className="h-[319px]"/>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="col-span-2">
              <img src="./Rectangle 41.png" alt="" className=""/>
            </div>
            <div className="col-span-1">
              <img src="./Rectangle 42.png" alt="" className="max-h-[395px] w-full"/>
            </div>
          </div>
        </div>
        </div>
        <div id="sister-companies">
          <SisterCompanies id="sister-companies"/>
        </div>
      </div>
  );
}
