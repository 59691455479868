import React, {useEffect, useRef, useState} from "react";
import { ReactComponent as Bag } from "../icons/bag.svg";
import { ReactComponent as Search } from "../icons/search.svg";
import { ReactComponent as Person } from "../icons/person.svg";
import { Menu, X } from "lucide-react";

export default function Header() {
  const [toggleMenu, setToggleMenu] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const submenuRef = useRef(null);

    // Function to close submenu if clicked outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (submenuRef.current && !submenuRef.current.contains(event.target)) {
                setIsSubmenuOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [submenuRef]);
  return (
      <div className="font-din header-shadow flex items-center justify-between bg-white px-4 sm:px-10">
          <div className="flex items-center gap-12">
              <div className="py-8">
                  <a href="/" aria-label="logo">
                      <img src="./125_Anniversary_LC_Logo.png" alt="logo" className="max-h-[55px]"/>
                  </a>
              </div>
              <div className="hidden space-x-8 xl:flex">
                  <a href="/who-we-are" className="text-sm transition hover:opacity-80">
                      WHO WE ARE
                  </a>
                  <a href="/solutions" className="text-sm transition hover:opacity-80">
                      SOLUTIONS
                  </a>
                  <a href="/portfolio" className="text-sm transition hover:opacity-80">
                      PORTFOLIO
                  </a>
                  <div className="relative group text-sm transition hover:opacity-80">
                      <a className="cursor-pointer">CONNECT</a>
                      <div
                          className="absolute hidden group-hover:block dropdown-content bg-white shadow-md">
                          <a href="/request-an-estimate" className="block px-4 py-2 text-sm hover:bg-gray-100">Get A Quote</a>
                          <a href="/send-a-file" className="block px-4 py-2 text-sm hover:bg-gray-100">Upload Files</a>
                          <a href="/contact-us" className="block px-4 py-2 text-sm hover:bg-gray-100">Contact Us</a>
                          <a href="/faq" className="block px-4 py-2 text-sm hover:bg-gray-100">FAQ</a>
                      </div>
                  </div>
              </div>
          </div>
          <div className="ml-auto hidden items-center self-stretch md:flex">
              <button
                  className="font-din-normal mr-8 rounded-full bg-[#ED1589] transition hover:opacity-80 px-10 py-3 text-lg tracking-wide text-white">
                  <a href="/request-an-estimate">GET A QUOTE</a>
              </button>
          </div>
          <button
              aria-label="hamburger menu"
              className="xl:hidden mx-5"
              onClick={() => setToggleMenu(!toggleMenu)}
          >
              <Menu className="size-10 stroke-1" />
          </button>
          <div
              className={`fixed flex flex-col gap-5 p-12 top-0 left-0 w-full transition z-50 bg-[#F1F1F1] h-screen ${
                  toggleMenu ? "translate-x-0" : "-translate-x-full"
              }`}
          >
              <button
                  aria-label="close menu button"
                  className="ml-auto"
                  onClick={() => setToggleMenu(!toggleMenu)}
              >
                  <X className="size-10" />
              </button>

              <div className="flex flex-col space-y-12 mt-12">
                  <a href="/who-we-are" className="text-lg transition hover:opacity-80">
                      WHO WE ARE
                  </a>
                  <a href="/solutions" className="text-lg transition hover:opacity-80">
                      SOLUTIONS
                  </a>
                  <a href="/portfolio" className="text-lg transition hover:opacity-80">
                      PORTFOLIO
                  </a>
                  <div className="relative text-lg transition hover:opacity-80">
                      <button onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
                              className="text-lg transition hover:opacity-80">
                          CONNECT
                      </button>
                      {isSubmenuOpen && (
                          <div className="bg-[#F1F1F1] mt-2">
                              <a href="/request-an-estimate" className="block py-2 px-4 text-md hover:bg-gray-600">Get A Quote</a>
                              <a href="/send-a-file" className="block py-2 px-4 text-md hover:bg-gray-600">Upload Files</a>
                              <a href="/contact-us" className="block py-2 px-4 text-md hover:bg-gray-600">Contact Us</a>
                              <a href="/faq" className="block py-2 px-4 text-md hover:bg-gray-600">FAQ</a>
                          </div>
                      )}
                  </div>
              </div>
              <button
                  className="my-8 bg-[#ED1589] transition hover:opacity-80 px-10 py-3 text-lg tracking-wide text-white">
                  <a href="/request-an-estimate">GET A QUOTE</a>
              </button>
          </div>
      </div>
  );
}
